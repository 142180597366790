import * as React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import useStoryblok from "../lib/storyblok"
import { render, NODE_PARAGRAPH, NODE_IMAGE } from 'storyblok-rich-text-react-renderer'
import dateFormat from 'dateformat'

import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"
import Teaser from "../components/common/Teaser/Teaser"
import Breadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs"
import { CurrentPageContext } from "../context"
import cn from 'classnames'

const JournalPost = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage} = React.useContext(CurrentPageContext);
  React.useEffect(() => {
    setCurrentPage([{
      slug: '/home-decor-blog/',
      name: 'Journal'
    },
    {
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])

  let allTags = useStaticQuery(graphql`
    {
      tags: allStoryblokEntry(
        sort: {fields: created_at}
        filter: {field_component: {eq: "tag"}}
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
          }
        }
      }
    }
  `)

  let filteredTags = [];
  if (allTags) {
    filteredTags = allTags.tags.edges

    filteredTags = filteredTags.map((p) => {
      const content = p.node.content
      const newContent = typeof content === 'string' ? JSON.parse(content) : content
      p.node.content = newContent
      return p.node
    })
  }

  let components = "";
  if (story.content.body){
    components = story.content.body.map(blok => {
      return (<DynamicComponent blok={blok} key={blok._uid} />)
    })
  }

  story.content['tagsSelected'] = filteredTags.filter(t => (story.content.tags && story.content.tags.includes(t.uuid)));

  const journalpost = story.content
  const teaserBlok = {
      image: journalpost.image,
      tagline: journalpost.type + ': ' + journalpost.title,
      image_height: 500,
      shifted_header_type: true,
      css_class: 'studio-page',
      banner_type: 'background',
      align: 'center'
  }

  let allImages = [];

  if (journalpost.image && journalpost.image.filename){
    allImages.push(journalpost.image.filename);
  }

  journalpost.description && journalpost.description.content.map(p1 => {
    p1.content && p1.content.map(p2 => {
      if (p2.type == 'image'){
        allImages.push(p2.attrs.src);
      }
      return p2;
    })
    return p1;
  });

  allImages = allImages.map(item => {
    return {
      "@type": "imageObject",
      "url": item.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk')
    }
  });

  const siteData = pageContext.siteInfo;
  const schema_json = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": journalpost.title_meta,
    "description": journalpost.description_meta,
    "image": allImages,  
    "author": {
      "@type": "Organization",
      "name": siteData.name,
      "url": siteData.siteUrl,
      "logo": siteData.logo,
    },  
    "datePublished": journalpost.date,
  };
  const schema = JSON.stringify(schema_json);

  return (
  <>
    <Seo title={journalpost.title_meta} description={journalpost.description_meta} schemaMarkup={schema} image={journalpost.image.filename.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk')} url={location.href} />
    <div className="at-journals__page">
      <Teaser blok={teaserBlok} />

      <div className="at-journals__page-wrapper" style={{marginTop: '0px'}}>
        <Breadcrumbs />
      </div>
      <div className="at-journals__page-wrapper">
        <div className="at-journals__page-top">
          <Link to="/home-decor-blog" className="at-journals__one-back">&#60; Back to Journal</Link>
          <div className="at-journals__one-date">{dateFormat(journalpost.date, "mmmm dS, yyyy")} | {journalpost.type}</div>
        </div>
        
        <div className="at-journals__one-fulltext">
          {
            render(
              journalpost.description,
                {
                  nodeResolvers: {
                    [NODE_PARAGRAPH]: (children) => {
                      children && children.map(item => {
                        if (item.type === 'a'){
                          if (item.props.href.indexOf("/") === 0){
                            item.props.rel = 'follow';
                            item.props.href = item.props.href.replace('/tags/', '/');
                            item.props.href = item.props.href.replace('/journal-posts/', '/');
                            if (item.props.href.lastIndexOf("/") !==  (item.props.href.length - 1)){
                              item.props.href = item.props.href + "/";
                            }
                          }else{
                            item.props.rel = 'nofollow';
                          }                         
                        } 
                        return true;
                      });
                      return (<p>{children}</p>);
                    },
                    [NODE_IMAGE]: (children, props) => {
                      props.src = props.src.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk');
                      return (<img {...props} />);
                    }
                  }               
                }                    
            )                
          }    
        </div>
        <div className="at-journals__one-tags">
          {journalpost.tagsSelected && journalpost.tagsSelected.map(tag => {
            return (<Link to={"/" + tag.slug + "/"} className="at-journals__one-tags-item">{tag.content.name}</Link>)
          })}
        </div>        
      </div>

      { components }
    </div>
  </>
  )
}

export default JournalPost
